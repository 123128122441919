import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w10HH01OrdnungVSSeele = () => (
  <Layout>
    <SEO title="w10HH01OrdnungVSSeele" />

    <h1 id="ueberschrift">Haushalt</h1>
    <h2>Ist Ordnung ein Spiegel der Seele? &emsp;  ©&nbsp;1998</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w10HH01OrdnungVSSeele&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Immer wieder tauchen Fragen und Feststellungen auf, welche die Ordnung in einem Zimmer oder Haushalt
        in direkten Zusammenhang mit dem inneren Selbst des Bewohners setzen. Kurz:
        <br />
        Schlampige Wohnung = Chaotische Seele -> "Unfähigkeit"
        <br />
        Ist es wirklich so einfach? Was steckt wirklich dahinter?
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, in einem besseren Einklang
        mit Ihrem Körper (und Geist) zu leben.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w10HH01OrdnungVSSeele
